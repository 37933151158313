<template>
  <div>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-6 col-12">Add Guest</div>
  </div>
  <div class="search-voter-section-outer" style="height: calc(100vh - 141px)">
    <div class="layout-content-section">
      <div class="form-box-outer">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12">
            <div class="custom-form-group">
              <label class="form-label"
                >Select Guest is<span class="text-danger">*</span></label
              >
              <div class="custom-group-radio-box-btn">
                <input
                  type="radio"
                  class="btn-check"
                  v-model="guesttype"
                  name="callstatus-option"
                  id="indian"
                  autocomplete="off"
                  v-bind:value="1"
                />
                <label
                  class="btn custom-radio-box-btn text-capitalize"
                  for="indian"
                  >Indian</label
                >
                <input
                  type="radio"
                  class="btn-check"
                  :disabled="disabledinput"
                  v-model="guesttype"
                  name="callstatus-option"
                  id="foreigner"
                  autocomplete="off"
                  v-bind:value="2"
                />
                <label class="btn text-capitalize" for="foreigner"
                  >Foreigner</label
                >
              </div>
              <div class="custom-error" v-if="v$.guesttype.$error">
                {{ v$.guesttype.$errors[0].$message }}
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="guesttype == 1">
          <div class="col-lg-12 col-md-12 col-12">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    >Mobile No.<span class="text-danger">*</span></label
                  >
                  <div class="input-group custom-input-group">
                    <div class="col-lg-4 col-md-4 col-12">
                      <Multiselect
                        v-model="addind.countrycode"
                        :options="countrynameList"
                        label="label"
                        :searchable="true"
                        placeholder="Select"
                        disabled
                        class="multiselect-custom custom-title-multiselect"
                        :canClear="false"
                        :closeOnSelect="true"
                        :object="true"
                        noOptionsText="No Result"
                        :canDeselect="false"
                      >          
  </Multiselect>
                    </div>
                    <div class="col-lg-8 col-md-8 col-12">
                      <input
                        type="text"
                        v-model="addind.mobilenumber"
                        id="editmobileInput"
                        class="form-control"
                        placeholder="Enter Mobile No."
                        :disabled="disabledinput || searchbtnloader"
                        maxlength="10"
                        @keypress="onlymobileEditFormat"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                  <div
                    class="custom-error"
                    v-if="v$.addind.mobilenumber.$error"
                  >
                    {{ v$.addind.mobilenumber.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-12" v-if="!disabledinput">
                <div class="custom-form-group" style="margin-top: 26px">
                  <label class="form-label"></label>
                  <button
                    type="button"
                    class="header-btn-outer btn btn-primary"
                    :style="{ width: '100px' }"
                    @click="searchExistVisitorBtn(addind.mobilenumber)"
                    :disabled="
                      addind.mobilenumber.length != 10 ||
                      disabledinput ||
                      searchbtnloader
                    "
                  >
                    <span v-if="!searchbtnloader">Search</span>
                    <div
                      class="spinner-border text-light custom-spinner-loader-btn"
                      role="status"
                      v-if="searchbtnloader"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                </div>
              </div>
              <div class="col text-end" v-if="disabledinput">
                <div class="custom-form-group" style="margin-top: 26px">
                  <label class="form-label"></label>
                  <button
                    type="button"
                    class="header-btn-outer btn btn-primary"
                    :style="{ width: '100px' }"
                    @click="clearall()"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
            <div class="row" v-if="searchMobileResult != ''">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="voter-exit-box-outer mb-3">
                  <div class="row">
                    <div
                      class="col-lg-2 col-md-2 col-12 d-flex align-items-center user-profile-table-col"
                    >
                      <div class="customer-label-group">
                        <div class="d-flex align-items-center">
                          <div>
                            <img
                              v-if="
                                searchMobileResult.aa46 == null ||
                                searchMobileResult.aa46 == ''
                              "
                              src="/assets/images/profile.svg"
                              class="rounded bg-light me-auto d-block"
                              alt=""
                              style="width: 72px; height: 72px"
                            />
                            <img
                              v-if="
                                searchMobileResult.aa46 != null &&
                                searchMobileResult.aa46 != ''
                              "
                              :src="
                                'https://storage.googleapis.com/' +
                                this.gueststorageimgpath +
                                '/visitor/profile/' +
                                searchMobileResult.aa46
                              "
                              class="rounded bg-light me-auto d-block voter-photo-img"
                              alt=""
                              style="width: 72px; height: 72px"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12 user-name-table-col">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Guest Name</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                searchMobileResult.aa9
                                  ? searchMobileResult.aa9
                                  : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label"
                              >Country<span class="mx-1 vertical-pipe-color"
                                >|</span
                              >State<span class="mx-1 vertical-pipe-color"
                                >|</span
                              >City</label
                            >
                            <div class="from-label-value text-capitalize">
                              {{
                                searchMobileResult.aa18
                                  ? searchMobileResult.aa18
                                  : "N/A"
                              }}<span class="mx-1 vertical-pipe-color">|</span
                              >{{
                                searchMobileResult.aa20
                                  ? searchMobileResult.aa20
                                  : "N/A"
                              }}<span class="mx-1 vertical-pipe-color">|</span
                              >{{
                                searchMobileResult.aa22
                                  ? searchMobileResult.aa22
                                  : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label"
                              >Gender<span class="mx-1 vertical-pipe-color"
                                >|</span
                              >Age</label
                            >
                            <div class="from-label-value text-capitalize">
                              {{
                                searchMobileResult.aa12
                                  ? searchMobileResult.aa12 == 1
                                    ? "Male"
                                    : searchMobileResult.aa12 == 2
                                    ? "Female"
                                    : "Others"
                                  : "N/A"
                              }}<span class="mx-1 vertical-pipe-color">|</span
                              >{{
                                searchMobileResult.aa11
                                  ? searchMobileResult.aa11
                                  : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-12 user-name-table-col">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Visitor Type</label>
                            <div class="from-label-value">
                              <span v-if="searchMobileResult.aa30 == 1"
                                >Indian</span
                              >
                              <span v-if="searchMobileResult.aa30 == 2"
                                >Non-Resident Indian (NRI)</span
                              >
                              <span
                                v-if="
                                  searchMobileResult.aa30 == '' ||
                                  searchMobileResult.aa30 == null
                                "
                                >N/A</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Aadhar No.</label>
                            <div class="from-label-value" v-if="isShowAadharNo == 0">
                                +91-{{ maskedNumber(searchMobileResult.aa33) }}
                            </div>
                            <div class="from-label-value" v-else-if="isShowAadharNo == 1">
                                +91-{{ searchMobileResult.aa33 }}
                            </div>
                            <div class="from-label-value" v-else>
                                {{ "N/A" }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-12 user-name-table-col">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div
                            class="customer-label-group mb-2 d-flex justify-content-between"
                          >
                            <div>
                              <label class="form-label"
                                >Last Reg. Date Time</label
                              >
                              <div class="from-label-value">
                                {{ format_timestamp(searchMobileResult.aa26) }}
                              </div>
                            </div>
                            <div class="d-flex align-items-end">
                              <button
                                type="button"
                                title="Edit Details"
                                class="btn custom-outline-view-btn me-2"
                                @click="
                                  visitorEditDetailModalOpen(searchMobileResult)
                                "
                              >
                                <i class="pi pi-pencil call-btn-color"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Mobile</label>
                            <div class="from-label-value">
                              +{{ searchMobileResult.aa44 }} {{ searchMobileResult.aa13 }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label"
                              >Last Visit Duration From<span
                                class="mx-1 vertical-pipe-color"
                                >|</span
                              >To</label
                            >
                            <div class="from-label-value">
                              {{
                                format_date(searchMobileResult.aa42)
                              }}<span class="mx-1 vertical-pipe-color">|</span
                              >{{ format_date(searchMobileResult.aa43)
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="mobileNoFoundFlag">
              <div class="col-lg-3 col-md-3 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    >Aadhar No.<span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    v-model="addind.aadharcardnumber"
                    class="form-control text-uppercase"
                    id="formaadharcardinput"
                    placeholder="Enter Aadhar No."
                    :disabled="hideaadharinput || showotploader"
                    autocomplete="off"
                    minlength="12"
                    maxlength="12"
                    @keypress="onlyNumber"
                  />
                  <div
                    class="custom-error"
                    v-if="v$.addind.aadharcardnumber.$error"
                  >
                    {{ v$.addind.aadharcardnumber.$errors[0].$message }}
                  </div>
                  <span class="custom-error" v-if="sendOTPMessage">{{
                    sendOTPMessage
                  }}</span>
                </div>
              </div>
              <div
                class="col-lg-2 col-md-2 col-12"
                v-if="!displayMobilePopUp && !hidesendotpbtn"
              >
                <div class="custom-form-group" style="margin-top: 26px">
                  <button
                    type="button"
                    class="header-btn-outer btn btn-primary"
                    :style="{ width: '100px' }"
                    @click="sendAadharOtp()"
                    :disabled="
                      addind.aadharcardnumber.length != 12 || showotploader
                    "
                  >
                    <span v-if="!showotploader">Send OTP</span>
                    <div
                      class="spinner-border text-light custom-spinner-loader-btn"
                      role="status"
                      v-if="showotploader"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-12" v-if="displayMobilePopUp">
                <div class="custom-form-group">
                  <label for="formaadharcardinput" class="form-label"
                    >Enter OTP <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    v-model="aadharotp"
                    class="form-control otp-input-space"
                    id="formaadharcardinput"
                    placeholder="Enter OTP"
                    autocomplete="off"
                    maxlength="6"
                    minlength="6"
                    @keypress="onlyNumber"
                    :disabled="mobile_otp_status == 1"
                  />
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <span class="custom-error" v-if="mobileotperr">
                      {{ mobileotperr }}
                    </span>
                    <span
                      v-if="timerMobileStatus"
                      class="otp-counter-text mt-1"
                      >{{ timerMobileInterval }}</span
                    >
                    <button
                      @click="resendAadharOtp()"
                      class="btn btn-link aadhar-resent-otp-btn"
                      v-if="resentotpbtnstatus"
                      :style="{ width: '80px' }"
                      :disabled="showresentloader"
                    >
                      <span v-if="!showresentloader">Resend OTP</span>
                      <div
                        class="spinner-border custom-spinner-icon-loader-btn"
                        role="status"
                        v-if="showresentloader"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-2 col-12" v-if="displayMobilePopUp">
                <div class="custom-form-group" style="margin-top: 26px">
                  <button
                    type="button"
                    class="header-btn-outer btn btn-success"
                    @click="verifyAadharOtp()"
                    :style="{ width: '80px' }"
                    :disabled="aadharotp.length != 6 || showverifyloader"
                  >
                    <span v-if="!showverifyloader">Verify</span>
                    <div
                      class="spinner-border text-light custom-spinner-loader-btn"
                      role="status"
                      v-if="showverifyloader"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                </div>
              </div>
              <div class="custom-img-error" v-if="systemlimiterrormsg">
                {{ systemlimiterrormsg }}
              </div>
            </div>
            <div class="row" v-if="AadharTabDetails != ''">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="voter-exit-box-outer mb-3">
                  <div class="row">
                    <div
                      class="col-lg-2 col-md-2 col-12 d-flex align-items-center"
                    >
                      <div class="customer-label-group">
                        <div class="d-flex align-items-center">
                          <div>
                            <img
                              v-if="
                                AadharTabDetails.ag12 == null ||
                                AadharTabDetails.ag12 == ''
                              "
                              src="/assets/images/profile.svg"
                              class="rounded bg-light me-auto d-block"
                              alt=""
                              style="width: 72px; height: 72px"
                            />
                            <img
                              v-if="
                                AadharTabDetails.ag12 != null &&
                                AadharTabDetails.ag12 != ''
                              "
                              :src="
                                'https://storage.googleapis.com/' +
                                this.gueststorageimgpath +
                                '/visitor/aadharphoto/' +
                                AadharTabDetails.ag12
                              "
                              class="rounded bg-light me-auto d-block"
                              alt=""
                              style="width: 72px; height: 72px"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Name</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                AadharTabDetails.ag4
                                  ? AadharTabDetails.ag4
                                  : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Aadhar Number</label>
                            <div class="from-label-value" v-if="isShowAadharNo == 1">
                              {{
                                AadharTabDetails.ag3
                                  ? AadharTabDetails.ag3
                                  : "N/A"
                              }}
                            </div>
                            <div class="from-label-value" v-else>
                              {{
                                maskedNumber(
                                  AadharTabDetails.ag3
                                    ? AadharTabDetails.ag3
                                    : "N/A"
                                )
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-lg-6 col-md-6 col-12 text-end" v-if="aadharupdatebtnflag">
                                              <button type="button" @click="confirmAadharUpdateModalOpen(AadharTabDetails.aa3)"
                                                  class="btn custom-view-detail-btn" :disabled="showupdateloader"
                                                  :style="{ width: '70px' }">
                                                  <span v-if="!showupdateloader">Update</span>
                                                  <div class="spinner-border custom-spinner-icon-loader-btn" role="status"
                                                      v-if="showupdateloader">
                                                      <span class="visually-hidden">Loading...</span>
                                                  </div>
                                              </button>
                                          </div> -->
                    <!-- <div class="col-lg-6 col-md-6 col-12 text-end" v-if="aadharUpdateBtnDaysMsg">
                                              <div class="col-12">
                                                  <div class="custom-img-error">{{ this.aadharUpdateBtnDaysMsg }}</div>
                                              </div>
                                          </div> -->
                  </div>
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Date of Birth | Age </label>
                        <div class="from-label-value">
                          {{ format_date(AadharTabDetails.ag8) }}&nbsp;|&nbsp;{{
                            AadharTabDetails.aa11
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Gender</label>
                        <div class="from-label-value">
                          {{
                            AadharTabDetails.ag24
                              ? AadharTabDetails.ag24 == 1
                                ? "Male"
                                : AadharTabDetails.ag24 == 2
                                ? "Female"
                                : "Others"
                              : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">House</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            AadharTabDetails.ag27
                              ? AadharTabDetails.ag27
                              : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Landmark</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            AadharTabDetails.ag28
                              ? AadharTabDetails.ag28
                              : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Street</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            AadharTabDetails.ag26
                              ? AadharTabDetails.ag26
                              : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Location</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            AadharTabDetails.ag19
                              ? AadharTabDetails.ag19
                              : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Post Office</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            AadharTabDetails.ag16
                              ? AadharTabDetails.ag16
                              : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Area</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            AadharTabDetails.ag20
                              ? AadharTabDetails.ag20
                              : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">City</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            AadharTabDetails.ag17
                              ? AadharTabDetails.ag17
                              : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Sub Dist</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            AadharTabDetails.ag25
                              ? AadharTabDetails.ag25
                              : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">State</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            AadharTabDetails.ag14
                              ? AadharTabDetails.ag14
                              : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-8 col-md-8 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Address</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            AadharTabDetails.ag6 ? AadharTabDetails.ag6 : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Pincode</label>
                        <div class="from-label-value">
                          {{
                            AadharTabDetails.ag7 ? AadharTabDetails.ag7 : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="aadharOTPVerifySuccessFlag">
              <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    >Select Reason To visit<span class="text-danger"
                      >*</span
                    ></label
                  >
                  <Multiselect
                    v-model="addind.visitreasonoption"
                    :object="true"
                    :options="visitReasonList"
                    :searchable="true"
                    label="label"
                    placeholder="Select Reason"
                    class="multiselect-custom text-capitalize"
                    :canClear="true"
                    :closeOnSelect="true"
                  />
                  <div
                    class="custom-error"
                    v-if="v$.addind.visitreasonoption.$error"
                  >
                    {{ v$.addind.visitreasonoption.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div
                class="col-lg-8 col-md-8 col-8"
                v-if="this.addind.visitreasonoption.label == 'Other'"
              >
                <div class="custom-form-group">
                  <label class="form-label"
                    >Reason To Visit<span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    v-model="addind.visitreason"
                    class="form-control"
                    placeholder="Enter Reason To Visit"
                    autocomplete="off"
                  />
                  <div class="custom-error" v-if="v$.addind.visitreason.$error">
                    {{ v$.addind.visitreason.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    >Check-In Date<span class="text-danger">*</span></label
                  >
                  <Calendar
                    v-model="addind.visitingdatefrom"
                    :showIcon="true"
                    class="custom-ultima-calendar"
                    placeholder="DD/MM/YYYY"
                    dateFormat="dd/mm/yy"
                    :minDate="new Date()"
                    :manualInput="false"
                    :monthNavigator="true"
                    :yearRange="'2020:' + new Date().getFullYear()"
                    appendTo="body"
                    :yearNavigator="true"
                  />
                  <div
                    class="custom-error"
                    v-if="v$.addind.visitingdatefrom.$error"
                  >
                    {{ v$.addind.visitingdatefrom.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    >Check-Out Date<span class="text-danger">*</span></label
                  >
                  <Calendar
                    v-model="addind.visitingdateto"
                    :disabled="addind.visitingdatefrom == null"
                    :showIcon="true"
                    class="custom-ultima-calendar"
                    placeholder="DD/MM/YYYY"
                    dateFormat="dd/mm/yy"
                    :minDate="new Date()"
                    :manualInput="false"
                    :monthNavigator="true"
                    :yearRange="'2020:' + new Date().getFullYear()"
                    appendTo="body"
                    :yearNavigator="true"
                  />
                  <div
                    class="custom-error"
                    v-if="v$.addind.visitingdateto.$error"
                  >
                    {{ v$.addind.visitingdateto.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="custom-form-group text-center">
                  <button
                    type="button"
                    @click="addGuestIndBtn()"
                    class="btn modal-bulk-next-btn"
                    :disabled="showloader"
                  >
                    <span v-if="!showloader">Submit</span>
                    <div
                      class="spinner-border text-light custom-spinner-loader-btn"
                      role="status"
                      v-if="showloader"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="guesttype == 2">
          <div class="col-lg-12 col-md-12 col-12">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    >Select Country<span class="text-danger">*</span></label
                  >
                  <Multiselect
                    v-model="addnri.countryname"
                    :options="countrynameList"
                    @select="handleCoutryNameByCountryCode(addnri.countryname.value)"
                    :searchable="true"
                    label="label"
                    placeholder="Select Country"
                    class="multiselect-custom text-capitalize"
                    :canClear="true"
                    :closeOnSelect="true"
                    :disabled="disablednriinput"
                    :object="true"
                  >
                  <template v-slot:option="{ option }">
                    <img class="character-option-icon me-2" :src="option.flag" width="18" alt="country-flag">{{ option.label }}
                  </template>
                  </Multiselect>
                  <div class="custom-error" v-if="v$.addnri.countryname.$error">
                    {{ v$.addnri.countryname.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    >Mobile No.<span class="text-danger">*</span></label
                  >
                  <div class="input-group custom-input-group">
                    <div class="col-lg-4 col-md-4 col-12">
                      <Multiselect
                        v-model="addnri.countrycodenra"
                        :options="countryCodeList"
                        :searchable="true"
                        label="label"
                        placeholder="Select"
                        class="multiselect-custom custom-title-multiselect"
                        :canClear="false"
                        :closeOnSelect="true"
                        :object="true"
                        noOptionsText="No Result"
                        :canDeselect="false"
                        :disabled="disablednriinput"
                      />
                    </div>
                    <div class="col-lg-8 col-md-8 col-12" v-if="this.addnri.countrycodenra.value == 101">
                      <input
                      type="text"
                        v-model="addnri.mobilenumbernra"
                        id="nrimobileInput"
                        class="form-control"
                        placeholder="Enter Mobile No."
                        :disabled="disablednriinput"
                        maxlength="10"
                        @keypress="onlymobileNriFormat"

                        autocomplete="off"
                      />
                    </div>
                    <div class="col-lg-8 col-md-8 col-12" v-else>
                      <input
                        type="text"
                        v-model="addnri.mobilenumbernra"
                        class="form-control"
                        placeholder="Enter Mobile No."
                        milength="8"
                        maxlength="15"
                        autocomplete="off"
                        @keypress="onlyNumber"
                        :disabled="disablednriinput"
                      />
                    </div>
                  </div>
                  <div
                    class="custom-error"
                    v-if="
                      v$.addnri.mobilenumbernra.$error &&
                      addnri.countrycodenra != ''
                    "
                  >
                    {{ v$.addnri.mobilenumbernra.$errors[0].$message }}
                  </div>
                  <div class="custom-error" v-if="v$.addnri.countrycodenra.$error">
                    {{ v$.addnri.countrycodenra.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-2 col-12" v-if="!disablednriinput">
                <div class="custom-form-group" style="margin-top: 26px">
                  <label class="form-label"></label>
                  <button
                    type="button"
                    class="header-btn-outer btn btn-primary"
                    :style="{ width: '100px' }"
                    @click="searchExistNriVisitorBtn(addnri.mobilenumbernra)"
                    :disabled="this.addnri.countrycodenra == '' || addnri.countryname == '' ||
                      addnri.mobilenumbernra.length < 8 ||
                      disablednriinput ||
                      searchnribtnloader
                    "
                  >
                    <span v-if="!searchnribtnloader">Search</span>
                    <div
                      class="spinner-border text-light custom-spinner-loader-btn"
                      role="status"
                      v-if="searchnribtnloader"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                </div>
              </div>
              <div class="col text-end" v-if="disablednriinput">
                <div class="custom-form-group" style="margin-top: 26px">
                  <label class="form-label"></label>
                  <button
                    type="button"
                    class="header-btn-outer btn btn-primary"
                    :style="{ width: '100px' }"
                    @click="clearall()"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
            <div class="row" v-if="searchNriMobileResult != ''">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="voter-exit-box-outer mb-3">
                  <div class="row">
                    <div
                      class="col-lg-2 col-md-2 col-12 d-flex align-items-center user-profile-table-col"
                    >
                      <div class="customer-label-group">
                        <div class="d-flex align-items-center">
                          <div>
                            <img
                              v-if="
                                searchNriMobileResult.aa46 == null ||
                                searchNriMobileResult.aa46 == ''
                              "
                              src="/assets/images/profile.svg"
                              class="rounded bg-light me-auto d-block"
                              alt=""
                              style="width: 72px; height: 72px"
                            />
                            <img
                              v-if="
                                searchNriMobileResult.aa46 != null &&
                                searchNriMobileResult.aa46 != ''
                              "
                              :src="
                                'https://storage.googleapis.com/' +
                                this.gueststorageimgpath +
                                '/visitor/profile/' +
                                searchNriMobileResult.aa46
                              "
                              class="rounded bg-light me-auto d-block voter-photo-img"
                              alt=""
                              style="width: 72px; height: 72px"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12 user-name-table-col">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Guest Name</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                searchNriMobileResult.aa9
                                  ? searchNriMobileResult.aa9
                                  : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label"
                              >Country<span class="mx-1 vertical-pipe-color"
                                >|</span
                              >State<span class="mx-1 vertical-pipe-color"
                                >|</span
                              >City</label
                            >
                            <div class="from-label-value text-capitalize">
                              {{
                                searchNriMobileResult.aa18
                                  ? searchNriMobileResult.aa18
                                  : "N/A"
                              }}<span class="mx-1 vertical-pipe-color">|</span
                              >{{
                                searchNriMobileResult.aa20
                                  ? searchNriMobileResult.aa20
                                  : "N/A"
                              }}<span class="mx-1 vertical-pipe-color">|</span
                              >{{
                                searchNriMobileResult.aa22
                                  ? searchNriMobileResult.aa22
                                  : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label"
                              >Gender<span class="mx-1 vertical-pipe-color"
                                >|</span
                              >Age</label
                            >
                            <div class="from-label-value text-capitalize">
                              {{
                                searchNriMobileResult.aa12
                                  ? searchNriMobileResult.aa12 == 1
                                    ? "Male"
                                    : searchNriMobileResult.aa12 == 2
                                    ? "Female"
                                    : "Others"
                                  : "N/A"
                              }}<span class="mx-1 vertical-pipe-color">|</span
                              >{{
                                searchNriMobileResult.aa11
                                  ? searchNriMobileResult.aa11
                                  : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-12 user-name-table-col">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Visitor Type</label>
                            <div class="from-label-value">
                              <span v-if="searchNriMobileResult.aa30 == 1"
                                >Indian</span
                              >
                              <span v-if="searchNriMobileResult.aa30 == 2"
                                >Non-Resident Indian (NRI)</span
                              >
                              <span
                                v-if="
                                  searchNriMobileResult.aa30 == '' ||
                                  searchNriMobileResult.aa30 == null
                                "
                                >N/A</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Passport No.</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                searchNriMobileResult.aa41
                                  ? searchNriMobileResult.aa41
                                  : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-12 user-name-table-col">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div
                            class="customer-label-group mb-2 d-flex justify-content-between"
                          >
                            <div>
                              <label class="form-label"
                                >Last Reg. Date Time</label
                              >
                              <div class="from-label-value">
                                {{ format_timestamp(searchNriMobileResult.aa26) }}
                              </div>
                            </div>
                            <div class="d-flex align-items-end">
                              <button
                                type="button"
                                title="Edit Details"
                                class="btn custom-outline-view-btn me-2"
                                @click="
                                  visitorEditDetailModalOpen(searchNriMobileResult)
                                "
                              >
                                <i class="pi pi-pencil call-btn-color"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Mobile</label>
                            <div class="from-label-value">
                              +{{ searchNriMobileResult.aa44 }} {{ searchNriMobileResult.aa13 }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label"
                              >Last Visit Duration From<span
                                class="mx-1 vertical-pipe-color"
                                >|</span
                              >To</label
                            >
                            <div class="from-label-value">
                              {{
                                format_date(searchNriMobileResult.aa42)
                              }}<span class="mx-1 vertical-pipe-color">|</span
                              >{{ format_date(searchNriMobileResult.aa43)
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="passportNoFoundFlag">
              <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    >Passport No.<span class="text-danger">*</span></label
                  >
                  <input
                    type="passport"
                    v-model="addnri.passportnumber"
                    class="form-control text-uppercase"
                    placeholder="Enter Passport Number"
                    minlength="8" maxlength="15"
                    autocomplete="off" :disabled="displayMobileNriPopUp"
                  />
                  <div class="custom-error" v-if="v$.addnri.passportnumber.$error">
                    {{ v$.addnri.passportnumber.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                      <label class="form-label">Birth Date<span class="text-danger">*</span></label>
                      <Calendar v-model="addnri.birthdate" 
                          :maxDate="maxDateBirth" :showIcon="true" class="custom-ultima-calendar"
                          placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" :manualInput="false"
                          :monthNavigator="true" :disabled="displayMobileNriPopUp"
                          :yearRange="'1950:' + maxDateBirth.getFullYear()" appendTo="body"
                          :yearNavigator="true">
                      </Calendar>
                      <div class="custom-error" v-if="v$.addnri.birthdate.$error">
                          {{ v$.addnri.birthdate.$errors[0].$message }}
                      </div>
                  </div>
              </div>
              <div class="col-lg-2 col-md-2 col-12" v-if="!displayMobileNriPopUp">
                <div class="custom-form-group" style="margin-top: 26px">
                  <button
                    type="button"
                    class="header-btn-outer btn btn-success"
                    @click="verifyPassportNumber()"
                    :style="{ width: '80px' }"
                    :disabled="addnri.passportnumber.length < 8 || addnri.birthdate == null || shownriverifyloader"
                  >
                    <span v-if="!shownriverifyloader">Verify</span>
                    <div
                      class="spinner-border text-light custom-spinner-loader-btn"
                      role="status"
                      v-if="shownriverifyloader"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                </div>
              </div>
              <div class="custom-img-error" v-if="systempassportlimiterrormsg">
                {{ systempassportlimiterrormsg }}
              </div>
            </div>
            <div class="row" v-if="PassportTabDetails != '' && isShowNriData">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="voter-exit-box-outer mb-3">
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Name</label>
                        <div class="from-label-value text-capitalize">
                          {{
                            PassportTabDetails.aa9 ? PassportTabDetails.aa9 : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Date of Birth | Age </label>
                        <div class="from-label-value">
                          {{ format_date(PassportTabDetails.aa10) }}&nbsp;|&nbsp;{{
                            PassportTabDetails.aa11 ? PassportTabDetails.aa11 : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Gender</label>
                        <div class="from-label-value">
                          {{
                            PassportTabDetails.aa12
                              ? PassportTabDetails.aa12 == 1
                                ? "Male"
                                : PassportTabDetails.aa12 == 2
                                ? "Female"
                                : "Others"
                              : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Mobile No.</label>
                        <div class="from-label-value">
                          {{
                            PassportTabDetails.aa13 ? PassportTabDetails.aa13 : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Country Name</label>
                        <div class="from-label-value">
                          {{
                            PassportTabDetails.aa18 ? PassportTabDetails.aa18 : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Passport No.</label>
                        <div class="from-label-value text-uppercase">
                          {{
                            PassportTabDetails.aa41 ? PassportTabDetails.aa41 : "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="passportVerifySuccessFlag">
              <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    >Visa Number<span class="text-danger">*</span></label
                  >
                  <input
                    type="passport"
                    v-model="addnri.visanumber"
                    class="form-control"
                    placeholder="Enter Visa Number"
                    maxlength="8"
                    minlength="8"
                    autocomplete="off"
                    @keypress="onlyNumber"
                  />
                  <div class="custom-error" v-if="v$.addnri.visanumber.$error">
                    {{ v$.addnri.visanumber.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    >Visa Expiry Date<span class="text-danger">*</span></label
                  >
                  <Calendar
                    v-model="addnri.visiexpirydate"
                    :showIcon="true"
                    class="custom-ultima-calendar"
                    placeholder="DD/MM/YYYY"
                    dateFormat="dd/mm/yy"
                    :minDate="new Date()"
                    :manualInput="false"
                    :monthNavigator="true"
                    :yearRange="'2020:' + new Date().getFullYear()"
                    appendTo="body"
                    :yearNavigator="true"
                  />
                  <div class="custom-error" v-if="v$.addnri.visiexpirydate.$error">
                    {{ v$.addnri.visiexpirydate.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    >Select Reason To visit<span class="text-danger">*</span></label
                  >
                  <Multiselect
                    v-model="addnri.visitreasonoptionnra"
                    :object="true"
                    :options="visitReasonList"
                    :searchable="true"
                    label="label"
                    placeholder="Select Reason"
                    class="multiselect-custom text-capitalize"
                    :canClear="true"
                    :closeOnSelect="true"
                  />
                  <div
                    class="custom-error"
                    v-if="v$.addnri.visitreasonoptionnra.$error"
                  >
                    {{ v$.addnri.visitreasonoptionnra.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div
                class="col-lg-12 col-md-12 col-12"
                v-if="addnri.visitreasonoptionnra.label == 'Other'"
              >
                <div class="custom-form-group">
                  <label class="form-label"
                    >Reason To Visit<span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    v-model="addnri.visitreasonnra"
                    class="form-control"
                    placeholder="Enter Reason To Visit"
                    autocomplete="off"
                  />
                  <div class="custom-error" v-if="v$.addnri.visitreasonnra.$error">
                    {{ v$.addnri.visitreasonnra.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    >Visiting From<span class="text-danger">*</span></label
                  >
                  <Calendar
                    v-model="addnri.visitingdatefromnir"
                    :showIcon="true"
                    class="custom-ultima-calendar"
                    placeholder="DD/MM/YYYY"
                    dateFormat="dd/mm/yy"
                    :minDate="new Date()"
                    :manualInput="false"
                    :monthNavigator="true"
                    :yearRange="'2020:' + new Date().getFullYear()"
                    appendTo="body"
                    :yearNavigator="true"
                  />
                  <div
                    class="custom-error"
                    v-if="v$.addnri.visitingdatefromnir.$error"
                  >
                    {{ v$.addnri.visitingdatefromnir.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    >Visiting Till<span class="text-danger">*</span></label
                  >
                  <Calendar
                    v-model="addnri.visitingdatetonri"
                    :disabled="addnri.visitingdatefromnir == null"
                    :showIcon="true"
                    class="custom-ultima-calendar"
                    placeholder="DD/MM/YYYY"
                    dateFormat="dd/mm/yy"
                    :minDate="new Date()"
                    :manualInput="false"
                    :monthNavigator="true"
                    :yearRange="'2020:' + new Date().getFullYear()"
                    appendTo="body"
                    :yearNavigator="true"
                  />
                  <div
                    class="custom-error"
                    v-if="v$.addnri.visitingdatetonri.$error"
                  >
                    {{ v$.addnri.visitingdatetonri.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="custom-form-group text-center">
                  <button
                    type="button"
                    @click="addGuestNriBtn()"
                    class="btn modal-bulk-next-btn"
                    :disabled="showloader"
                  >
                    <span v-if="!showloader">Submit</span>
                    <div
                      class="spinner-border text-light custom-spinner-loader-btn"
                      role="status"
                      v-if="showloader"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Edit Voter Detail start here -->
  <div class="modal-mask" v-if="editvisitorindmodalstatus">
    <div
      class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered custom-modal-outer"
    >
      <div class="modal-content">
        <div class="modal-header custom-custmer-header-border">
          <h5 class="modal-title" id="staticBackdropLabel">
            Edit Guest Detail
          </h5>
          <button
            type="button"
            class="btn-close"
            @click="visitorEditDetailModalClose()"
          ></button>
        </div>
        <div class="modal-body modal-body-scroll-outer">
          <div style="position: relative; min-height: 200px">
            <div v-if="!showloadervotermodal">
              <div class="row">
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label for="formTitleInput" class="form-label"
                      >First Name<span class="text-danger">*</span></label
                    >
                    <div class="input-group custom-input-group">
                      <div class="col-lg-4 col-md-4 col-12">
                        <Multiselect
                          v-model="editindvisitor.title"
                          :options="titleList"
                          :searchable="false"
                          label="label"
                          placeholder="Select"
                          class="multiselect-custom custom-title-multiselect text-capitalize"
                          :canClear="false"
                          :closeOnSelect="true"
                          :object="true"
                          noOptionsText="No Result"
                          :canDeselect="false"
                        />
                      </div>
                      <div class="col-lg-8 col-md-8 col-12">
                        <input
                          type="text"
                          v-model="editindvisitor.firstname"
                          class="form-control text-capitalize"
                          id="formFirstNameInput"
                          placeholder="Enter First Name"
                          autocomplete="off"
                          maxlength="20"
                          @keypress="isLetterWithOutSpace($event)"
                        />
                      </div>
                    </div>
                    <div
                      class="custom-error"
                      v-if="v$.editindvisitor.firstname.$error"
                    >
                      {{ v$.editindvisitor.firstname.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label for="formLastNameInput" class="form-label"
                      >Middle Name</label
                    >
                    <input
                      type="text"
                      v-model="editindvisitor.middlename"
                      class="form-control text-capitalize"
                      id="formLastNameInput"
                      placeholder="Enter Middle Name"
                      autocomplete="off"
                      maxlength="20"
                      @keypress="isLetterWithOutSpace($event)"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label for="formLastNameInput" class="form-label"
                      >Last Name<span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      v-model="editindvisitor.lastname"
                      class="form-control text-capitalize"
                      id="formLastNameInput"
                      placeholder="Enter Last Name"
                      autocomplete="off"
                      maxlength="20"
                      @keypress="isLetterWithOutSpace($event)"
                    />
                    <div
                      class="custom-error"
                      v-if="v$.editindvisitor.lastname.$error"
                    >
                      {{ v$.editindvisitor.lastname.$errors[0].$message }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label for="formEmailInput" class="form-label"
                      >Email Id</label
                    >
                    <input
                      type="text"
                      v-model="editindvisitor.email"
                      class="form-control"
                      id="formEmailInput"
                      placeholder="Enter Email Id"
                      autocomplete="off"
                      maxlength="50"
                    />
                    <div
                      class="custom-error"
                      v-if="v$.editindvisitor.email.$error"
                    >
                      {{ v$.editindvisitor.email.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Upload Profile</label>
                    <FileUpload
                      mode="basic"
                      ref="visitorprofileimg"
                      class="custom-upload-btn-ultima"
                      v-model="editindvisitor.visitorprofileimg"
                      v-on:change="handleUploadVisitorProfile()"
                    />
                    <div class="custom-error" v-if="errorprofileimage">
                      {{ errorprofileimage }}
                    </div>
                    <div class="text-primary" v-if="exitpreviewurl != null">
                      {{ exitpreviewurl.name }}
                    </div>
                  </div>
                </div>
                <div
                  class="col-lg-4 col-md-4 col-12"
                  v-if="exitpreviewurl == null"
                >
                  <img
                    v-if="
                      editindvisitor.importprofilefile != null &&
                      editindvisitor.importprofilefile != ''
                    "
                    :src="editindvisitor.importprofilefile"
                    class="rounded bg-light me-auto d-block voter-photo-img"
                    alt=""
                  />
                  <img
                    src="/assets/images/profile.svg"
                    v-if="editindvisitor.importprofilefile == null"
                    class="rounded bg-light me-auto d-block voter-photo-img"
                    alt=""
                  />
                </div>
                <div
                  class="col-lg-4 col-md-4 col-12"
                  v-if="exitpreviewurl != null"
                >
                  <img
                    :src="exitpreviewurl.objectURL"
                    class="rounded bg-light me-auto d-block voter-photo-img"
                    alt=""
                  />
                </div>
                <div
                  class="col-lg-4 col-md-4 col-12"
                  v-if="
                    editindvisitor.importprofilefile == null &&
                    editindvisitor.importprofilefile == '' &&
                    exitpreviewurl == null
                  "
                >
                  <img
                    src="/assets/images/profile.svg"
                    class="rounded bg-light me-auto d-block voter-photo-img"
                    alt=""
                  />
                </div>

                <!-- <div class="col-lg-4 col-md-4 col-12">
                                  <img src="/assets/images/profile.svg" v-if="editindvisitor.importprofilefile == null && editindvisitor.importprofilefile == ''" class="
                                  rounded
                                  bg-light
                                  me-auto
                                  d-block
                                  voter-photo-img
                                " alt="" style="width: 72px; height: 72px;" />
                                    <img v-if="editindvisitor.importprofilefile != null && editindvisitor.importprofilefile != ''"
                                        :src="editindvisitor.importprofilefile" class="
                                    rounded
                                    bg-light
                                    me-auto
                                    d-block
                                    voter-photo-img
                                  " alt="" style="width: 72px; height: 72px;" />
                                </div> -->
              </div>
            </div>
            <div v-if="showloadervotermodal">
              <div class="custom-modal-spinner-loader">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 text-end">
            <button
              type="button"
              class="btn modal-bulk-next-btn"
              @click="updateVisitorDetailbtn()"
            >
              <span v-if="!editvisitorloader">Submit</span>
              <div
                class="spinner-border text-light custom-spinner-loader-btn"
                role="status"
                v-if="editvisitorloader"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Edit Voter Detail end here -->
</div>
</template>
<script>
import commonMixin from "../../../mixins/commonMixin.js";
import ApiService from "../../../service/ApiService";
import useValidate from "@vuelidate/core";
import moment from "moment";
import {
  required,
  minLength,
  email,
  requiredIf,
  helpers,
} from "@vuelidate/validators";
export default {
  mixins: [commonMixin],
  data() {
    return {
      v$: useValidate(),
      showloader: false,
      guesttype: "",
      // indian
      addind: {
        countrycode: { value: 101, label: "+91" },
        mobilenumber: "",
        aadharcardnumber: "",
        visitreasonoption: "",
        visitreason: "",
        visitingdatefrom: new Date(),
        visitingdateto: null,
      },

      // Foreigner
      addnri: {
        countryname: "",
        countrycodenra: "",
        mobilenumbernra: "",
        passportnumber: "",
        birthdate: null,
        visanumber: "",
        visiexpirydate: null,
        visitreasonoptionnra: "",
        visitreasonnra: "",
        visitingdatefromnir: new Date(),
        visitingdatetonri: null,
      },
      countryCodeList: [],
      countrynameList: [],
      // OTP section
      showotploader: false,
      showverifyloader: false,
      searchbtnloader: false,
      searchMobileResult: [],
      disabledinput: false,
      mobileNoFoundFlag: false,
      timerMobileInterval: null,
      timerMobileStatus: false,
      timerMobileCount: 120,
      aadharOTPVerifySuccessFlag: false,
      hideaadharinput: false,
      displayMobilePopUp: false,
      aadharotp: "",
      mobileotperr: "",
      AadharTabDetails: "",
      client_info: "",
      gueststorageimgpath: "",
      editvisitorindmodalstatus: false,
      showloadervotermodal: false,
      editindvisitor: {
        title: { value: 1, label: "Mr." },
        firstname: "",
        middlename: "",
        lastname: "",
        email: "",
        visitorprofileimg: null,
        importprofilefile: "",
      },
      errorprofileimage: "",
      editvisitorloader: false,
      titleList: [],
      visitorIdFk: "",
      exitpreviewurl: null,
      otpAadharVisitorid: "",
      resentotpbtnstatus: false,
      mobile_otp_status: false,
      showresentloader: false,
      sendOTPMessage: "",
      systemlimiterrormsg: "",
      hidesendotpbtn: false,
      visitReasonList: [],
      isShowAadharNo: '',
      isShowMobileNo: '',
      user_info: '',
      searchnribtnloader: false,
      searchNriMobileResult: [],
      disablednriinput: false,
      PassportTabDetails: '',
      passportVerifySuccessFlag: false,
      passportNoFoundFlag: false,
      shownriverifyloader: false,
      systempassportlimiterrormsg: '',
      maxDateBirth: moment().subtract(18, "years")._d,
      displayMobileNriPopUp: false,
      guestfk: '',
      contryCodeInd:'+91',
      isShowNriData:true,
    };
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  validations() {
    return {
      guesttype: {
        required: helpers.withMessage("Please select guest type", required),
      },
      // indian
      addind: {
        mobilenumber: {
          requiredIf: helpers.withMessage(
            "Please enter mobile No.",
            requiredIf(this.guesttype == 1)
          ),
          minLength: helpers.withMessage(
            "Enter vaild mobile No.",
            minLength(10)
          ),
        },
        aadharcardnumber: {
          requiredIf: helpers.withMessage(
            "Please enter aadhar No.",
            requiredIf(this.guesttype == 1)
          ),
          minLength: helpers.withMessage(
            "Enter vaild aadhar No.",
            minLength(12)
          ),
        },
        visitreasonoption: {
          requiredIf: helpers.withMessage(
            "Please enter reason",
            requiredIf(this.guesttype == 1)
          ),
        },
        visitreason: {
          requiredIf: helpers.withMessage(
            "Please enter reason",
            requiredIf(
              this.guesttype == 1 &&
                this.addind.visitreasonoption.label == "Other"
            )
          ),
        },
        visitingdatefrom: {
          requiredIf: helpers.withMessage(
            "Please select date",
            requiredIf(this.guesttype == 1)
          ),
        },
        visitingdateto: {
          requiredIf: helpers.withMessage(
            "Please select date",
            requiredIf(this.guesttype == 1)
          ),
        },
      },
      // foreigner
      addnri: {
        countryname: {
          requiredIf: helpers.withMessage(
            "Please select country",
            requiredIf(this.guesttype == 2)
          ),
        },
        countrycodenra: {
          requiredIf: helpers.withMessage(
            "Please select country code",
            requiredIf(this.guesttype == 2)
          ),
        },
        mobilenumbernra: {
          requiredIf: helpers.withMessage(
            "Please enter mobile No.",
            requiredIf(this.guesttype == 2)
          ),
          minLength: helpers.withMessage(
            "Enter vaild mobile No.",
            minLength(8)
          ),
        },
        birthdate: {
          requiredIf: helpers.withMessage(
            "Please select birthdate",
            requiredIf(this.guesttype == 2)
          ),
        }, 
        passportnumber: {
          requiredIf: helpers.withMessage(
            "Please enter passport No.",
            requiredIf(this.guesttype == 2)
          ),
          minLength: helpers.withMessage(
            "Enter vaild passport No.",
            minLength(8)
          ),
        },
        visanumber: {
          requiredIf: helpers.withMessage(
            "Please enter visa No.",
            requiredIf(this.guesttype == 2)
          ),
          minLength: helpers.withMessage(
            "Enter vaild visa No.",
            minLength(8)
          ),
        },
        visiexpirydate: {
          requiredIf: helpers.withMessage(
            "Please select expiry date",
            requiredIf(this.guesttype == 2)
          ),
        },
        visitreasonoptionnra: {
          requiredIf: helpers.withMessage(
            "Please enter reason.",
            requiredIf(this.guesttype == 2)
          ),
        },
        visitreasonnra: {
          requiredIf: helpers.withMessage(
            "Please enter reason.",
            requiredIf(
              this.guesttype == 2 &&
                this.addnri.visitreasonoptionnra.label == "Other"
            )
          ),
        },
        visitingdatefromnir: {
          requiredIf: helpers.withMessage(
            "Please select date",
            requiredIf(this.guesttype == 2)
          ),
        },
        visitingdatetonri: {
          requiredIf: helpers.withMessage(
            "Please select date",
            requiredIf(this.guesttype == 2)
          ),
        },
      },

      editindvisitor: {
        firstname: {
          required: helpers.withMessage("Please enter first name", required),
        },
        lastname: {
          required: helpers.withMessage("Please enter last name", required),
        },
        email: {
          email: helpers.withMessage("Email address is invalid", email),
        },
      },
    };
  },
  mounted() {
    this.client_info = JSON.parse(localStorage.client_info);
    this.user_info = JSON.parse(localStorage.user);
    this.isShowMobileNo = this.user_info.ad39;
    this.isShowAadharNo = this.user_info.ad47;
    this.gueststorageimgpath = this.client_info.maa24;
    this.getCountryList();
    this.getalltitle();
    this.getCountryCodeApi();
    this.getVisitingReason();
  },
  methods: {
    getalltitle(e) {
      this.ApiService.getalltitle(e).then((data) => {
        if (data.status == 200) {
          this.titleList = data.data;
        }
      });
    },
    getVisitingReason(e) {
      this.ApiService.getVisitingReason(e).then((data) => {
        if (data.status == 200) {
          this.visitReasonList = data.data;
        } else {
          this.visitReasonList = null;
        }
      });
    },

    visitorEditDetailModalOpen(e) {
      // console.log(e);
      this.editvisitorindmodalstatus = true;
      this.editindvisitor.importprofilefile = "";
      this.editindvisitor.title = "";
      this.editindvisitor.firstname = "";
      this.editindvisitor.middlename = "";
      this.editindvisitor.lastname = "";
      this.editindvisitor.email = "";
      (this.visitorIdFk = ""), (this.editindvisitor.visitorprofileimg = null);
      this.editindvisitor.importprofilefile = "";
      this.errorprofileimage = "";
      this.exitpreviewurl = null;
      this.visitorIdFk = e.aa1;
      if (e.aa5 != null && e.aa5 != "") {
        this.editindvisitor.title = {
          label: e.aa5,
          value: e.aa4,
        };
      }
      this.editindvisitor.firstname = e.aa6;
      this.editindvisitor.middlename = e.aa7;
      this.editindvisitor.lastname = e.aa8;
      this.editindvisitor.email = e.aa14;
      if (e.aa46 != null) {
        this.editindvisitor.importprofilefile =
          "https://storage.googleapis.com/" +
          this.gueststorageimgpath +
          "/visitor/profile/" +
          e.aa46;
      } else {
        this.editindvisitor.importprofilefile = null;
      }
    },
    visitorEditDetailModalClose() {
      this.editvisitorindmodalstatus = false;
    },
    handleUploadVisitorProfile() {
      var file = this.$refs.visitorprofileimg.files[0];
      let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
      if (!file.size > 2048) {
        this.errorprofileimage = "File size cannot exceed more than 2MB";
      } else if (!file.name.match(allowedExtensions)) {
        this.errorprofileimage = "Invalid file type";
      } else {
        this.errorprofileimage = "";
        this.editindvisitor.visitorprofileimg =
          this.$refs.visitorprofileimg.files[0];
        this.exitpreviewurl = this.editindvisitor.visitorprofileimg;
      }
    },
    updateVisitorDetailbtn() {
      this.v$.editindvisitor.$validate();
      var formData = new FormData();
      formData.append("aa1", this.visitorIdFk);
      formData.append("aa4", this.editindvisitor.title.value);
      formData.append("aa5", this.editindvisitor.title.label);
      formData.append("aa6", this.editindvisitor.firstname);
      if (this.editindvisitor.middlename) {
        formData.append("aa7", this.editindvisitor.middlename);
      }
      formData.append("aa8", this.editindvisitor.lastname);
      if (this.editindvisitor.email) {
        formData.append("aa14", this.editindvisitor.email);
      }
      if (this.editindvisitor.visitorprofileimg) {
        formData.append(
          "visitorProfileImage",
          this.editindvisitor.visitorprofileimg
        );
      }
      if (!this.v$.editindvisitor.$error && !this.errorprofileimage) {
        this.editvisitorloader = true;
        this.ApiService.updateVisitor(formData).then((data) => {
          if (data.status == 200) {
            this.searchMobileResult = data.data;
            this.editvisitorloader = false;
            var successMsg = data.message;
            this.$toast.open({
              message: successMsg,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            setTimeout(() => {
              this.v$.$reset();
            }, 0);
            this.editvisitorindmodalstatus = false;
          } else {
            this.editvisitorloader = false;
            var errorMsg = data.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
          }
        });
      } else {
        this.$error;
      }
    },
    searchExistVisitorBtn(visitormobileno) {
      this.searchbtnloader = true;
      this.aa13 = visitormobileno;
      this.ApiService.getvisitorusingcontact({ aa13: this.aa13, aa44:this.contryCodeInd }).then(
        (data) => {
          if (data.success == true) {
            if (data.status == 200) {
              this.searchMobileResult = data.data;
              this.guestfk = data.data.aa1;
              this.addind.aadharcardnumber = data.data.aa33;
              this.aadharOTPVerifySuccessFlag = true;
            } else {
              this.mobileNoFoundFlag = true;
            }
            this.searchbtnloader = false;
            this.disabledinput = true;
          } else {
            this.searchMobileResult = null;
            this.searchbtnloader = false;
            this.disabledinput = false;
            this.mobileNoFoundFlag = true;
          }
        }
      );
    },
    sendAadharOtp() {
      this.showotploader = true;
      let fields = {};
      if (this.aa1) {
        fields["aa1"] = this.aa1;
      }
      fields["aa13"] = this.addind.mobilenumber;
      fields["aadhar_number"] = this.addind.aadharcardnumber;
      this.ApiService.generateaadharotp(fields).then((data) => {
        if (data.success === true) {
          if (data.is_otp === 1) {
            this.otpAadharVisitorid = data.data.aa1;
            // console.log(this.otpAadharVisitorid);
            var successMsggOtp = data.message;
            this.$toast.open({
              message: successMsggOtp,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            this.showotploader = false;
            this.hideaadharinput = true;
            this.displayMobilePopUp = true;
            this.startMobileTimer();
            this.timerMobileStatus = true;
            this.sendOTPMessage = data.message;
          } else {
            this.showotploader = false;
            this.hideaadharinput = true;
            // var successMsgg = data.message;
            // this.$toast.open({
            //   message: successMsgg,
            //   type: "success",
            //   duration: 3000,
            //   position: "top-right",
            // });
            this.AadharTabDetails = data.data.aadhardata;
            this.aadharOTPVerifySuccessFlag = true;
            this.hidesendotpbtn = true;
          }
        } else {
          var errorMsgg = data.message;
          this.$toast.open({
            message: errorMsgg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          this.showotploader = false;
          this.hideaadharinput = false;
          this.displayMobilePopUp = false;
          this.systemlimiterrormsg = data.msg;
        }
      });
    },

    verifyAadharOtp() {
      this.showverifyloader = true;
      let fields = {};
      fields["aa13"] = this.addind.mobilenumber;
      fields["aa1"] = this.otpAadharVisitorid;
      fields["otp"] = this.aadharotp;
      fields["aadhar_number"] = this.addind.aadharcardnumber;
      fields["update"] = 1;
      this.ApiService.getaadharcarddetails(fields).then((items) => {
        if (items.success === true) {
          this.hideaadharinput = true;
          this.displayMobilePopUp = false;
          this.aadharerr = "";
          this.timerMobileStatus = false;
          this.timerMobileCount = 120;
          this.showverifyloader = false;
          this.AadharTabDetails = items.data;
          this.guestfk = items.data.aa1;
          this.aadharOTPVerifySuccessFlag = true;
          this.sendOTPMessage = '';
          this.hidesendotpbtn = true;
          var successMsg = items.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
        } else if (items.status == 500) {
          this.mobile_otp_status = 1;
          this.sendOTPMessage = "";
        } else {
          this.AadharTabDetails = "";
          this.showverifyloader = false;
          this.mobileotperr = items.message;
          var errorMsg = items.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          this.timerMobileStatus = false;
          this.timerMobileCount = 0;
          this.aadharotp = "";
          this.showloader = false;
          this.resentotpbtnstatus = true;
          setTimeout(() => {
            this.mobile_otp_status = 1;
          }, 480000);
          // 8 minutes set timeout
          this.sendOTPMessage = "";
        }
      });
    },
    resendAadharOtp() {
      this.showresentloader = true;
      let fields = {};
      fields["aa1"] = this.otpAadharVisitorid;
      fields["aa13"] = this.addind.mobilenumber;
      fields["aadhar_number"] = this.addind.aadharcardnumber;
      fields["resend_otp"] = 1;
      fields["update"] = 1;
      this.ApiService.generateaadharotp(fields).then((items) => {
        if (items.success === true) {
          this.showupdateloader = false;
          var successMsg = items.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.displayMobilePopUp = true;
          this.startMobileTimer();
          this.timerMobileStatus = true;
          this.aadharotp = "";
          this.mobileotperr = "";
          this.showloader = false;
          this.sendOTPMessage = items.message;
          this.mobile_otp_status = 0;
          this.showresentloader = false;
          this.resentotpbtnstatus = false;
        } else {
          this.resentotpbtnstatus = false;
          var errorMsg = items.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          this.showresentloader = false;
          this.systemlimiterrormsg = items.msg;
        }
      });
    },
    startMobileTimer() {
      this.timerMobileStatus = true;
      if (this.timerMobileCount > 0) {
        setTimeout(() => {
          this.timerMobileCount--;
          this.startMobileTimer();
        }, 1000);
        const minutes = Math.floor(this.timerMobileCount / 60);
        let seconds = this.timerMobileCount % 60;
        if (seconds < 10) {
          this.timerMobileInterval = `0${minutes}:0${seconds}`;
        } else {
          this.timerMobileInterval = `0${minutes}:${seconds}`;
        }
      } else {
        this.timerMobileCount = 120;
        this.timerMobileStatus = false;
        // this.timerMobileCount = 0;
          this.aadharotp = "";
          this.resentotpbtnstatus = true;
      }
    },
    addGuestIndBtn() {
      this.v$.addind.$validate();
      let fields = {};
      fields["aa30"] = this.guesttype;
      if(this.guestfk){
        fields["aa1"] = this.guestfk;
      }
      if (this.guesttype == 1) {
        fields["aa13"] = this.addind.mobilenumber;
        fields["aa33"] = this.addind.aadharcardnumber;
        if (this.addind.visitreasonoption.label == "Other") {
          fields["aa36"] = this.addind.visitreason;
        } else {
          fields["aa48"] = this.addind.visitreasonoption.value;
          fields["aa49"] = this.addind.visitreasonoption.label;
        }
        fields["aa42"] = moment(this.addind.visitingdatefrom).format(
          "YYYY-MM-DD"
        );
        fields["aa43"] = moment(this.addind.visitingdateto).format(
          "YYYY-MM-DD"
        );
      }
      // console.log(fields);
      if (!this.v$.addind.$error) {
        
        this.showloader = true;
        this.ApiService.updateVisitorVisitingInfo(fields).then((items) => {
          if (items.status == 200) {
            this.showloader = false;
            this.$toast.open({
              message: items.message,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            setTimeout(() => {
              this.v$.$reset();
            }, 0);
            this.addind.guesttype = "";
            this.addind.mobilenumber = "";
            this.addind.aadharcardnumber = "";
            this.addind.visitreason = "";
            this.addind.visitingdatefrom = null;
            this.addind.visitingdateto = null;
            this.clearall();
          } else {
            this.$toast.open({
              message: items.message,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.showloader = false;
          }
        });
      }
    },
    searchExistNriVisitorBtn(visitornrimobileno) {
      this.aa13 = visitornrimobileno;
      this.searchnribtnloader = true;
      this.ApiService.getvisitorusingcontact({ aa13: this.aa13,aa44:this.addnri.countrycodenra }).then(
        (data) => {
          if (data.success == true) {
            if (data.status == 200) {
              this.searchNriMobileResult = data.data;
              this.guestfk = data.data.aa1;
              this.passportVerifySuccessFlag = true;
            } else {
              this.passportNoFoundFlag = true;
            }
            this.searchnribtnloader = false;
            this.disablednriinput = true;
          } else {
            this.searchNriMobileResult = null;
            this.searchnribtnloader = false;
            this.disablednriinput = false;
            this.passportNoFoundFlag = true;
            this.displayMobileNriPopUp = true;
          }
        }
      );
    },
    verifyPassportNumber() {
      this.shownriverifyloader = true;
      let fields = {};
      // console.log(this.addnri.countryname);
      fields["aa41"] = this.addnri.passportnumber;
      fields["aa10"] = moment(this.addnri.birthdate).format('YYYY-MM-DD');
      fields["aa13"] = this.addnri.mobilenumbernra;
      fields["aa17"] = this.addnri.countryname.value;
      fields["aa18"] = this.addnri.countryname.label;
      fields["aa44"] = this.addnri.countrycodenra.value;
      this.ApiService.addForeignerVisitor(fields).then((items) => {
        if (items.success == true) {
          this.shownriverifyloader = false;
          this.displayMobileNriPopUp = true;
          this.PassportTabDetails = items.data;
          this.guestfk = items.data.aa1;
          this.passportVerifySuccessFlag = true;
          this.isShowNriData = true;
          var successMsg = items.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          } else {
            this.shownriverifyloader = false;
            this.PassportTabDetails = '';
            this.systempassportlimiterrormsg = items.msg;
            var errorMsg = items.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
          }
      });
    },
    addGuestNriBtn() {
      this.v$.addnri.$validate();
      let fields = {};
      fields["aa30"] = this.guesttype;
      if(this.guestfk){
        fields["aa1"] = this.guestfk;
      }
      if (this.guesttype == 2) {
        fields["aa17"] = this.addnri.countryname.value;
        fields["aa18"] = this.addnri.countryname.label;
        fields["aa44"] = this.addnri.countrycodenra.value;
        fields["aa13"] = this.addnri.mobilenumbernra;
        fields["aa41"] = this.addnri.passportnumber;
        fields["aa39"] = this.addnri.visanumber;
        fields["aa40"] = moment(this.addnri.visiexpirydate).format(
          "YYYY-MM-DD"
        );
        if (this.addnri.visitreasonoptionnra.label == "Other") {
          fields["aa36"] = this.addnri.visitreasonnra;
        } else {
          fields["aa48"] = this.addnri.visitreasonoptionnra.value;
          fields["aa49"] = this.addnri.visitreasonoptionnra.label;
        }
        fields["aa42"] = moment(this.addnri.visitingdatefromnir).format(
          "YYYY-MM-DD"
        );
        fields["aa43"] = moment(this.addnri.visitingdatetonri).format(
          "YYYY-MM-DD"
        );
      }
      // console.log(fields);
      if (!this.v$.addnri.$error) {
        // console.log(fields);
        this.showloader = true;
        this.ApiService.updateVisitorVisitingInfo(fields).then((items) => {
          if (items.status == 200) {
            this.showloader = false;
            this.$toast.open({
              message: items.message,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            setTimeout(() => {
              this.v$.$reset();
            }, 0);
            this.addnri.countryname = "";
            this.addnri.countrycodenra = "";
            this.addnri.mobilenumbernra = "";
            this.addnri.passportnumber = "";
            this.addnri.birthdate = null;
            this.addnri.visanumber = "";
            this.addnri.visiexpirydate = null;
            this.addnri.visitreasonnra = "";
            this.addnri.visitreasonoptionnra = "";
            this.addnri.visitingdatefromnir = null;
            this.addnri.visitingdatetonri = null;
            this.clearall();
          } else {
            this.$toast.open({
              message: items.message,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.showloader = false;
          }
        });
      }
    },
    getCountryCodeApi(e) {
      this.ApiService.getCountryCode(e).then((data) => {
        if (data.status == 200) {
          this.countryCodeList = data.data;
        } else {
          this.countryCodeList = null;
        }
      });
    },
    handleCoutryNameByCountryCode(e) {
      this.addnri.countrycodenra = "";
      this.ApiService.getCountryCode().then((data) => {
        if (data.status == 200) {
          this.countryCodeList = data.data;
          let datafk = e;
          let vals = this.countryCodeList.filter(function (item) {
            return item.value == datafk;
          });
          this.addnri.countrycodenra = {
            value: vals[0].value,
            label: vals[0].label,
          };
        } else {
          this.countryCodeList = null;
        }
      });
    },

    getCountryList(e) {
      this.ApiService.getCountryList(e).then((data) => {
        if (data.status == 200) {
          this.countrynameList = data.data;
        } else {
          this.countrynameList = null;
        }
      });
    },

    
    
    onlymobileEditFormat($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }

      var editmobileInput = document.getElementById("editmobileInput");
      if (
        (editmobileInput.value == "" || editmobileInput.length > 1) &&
        (keyCode == 48 ||
          keyCode == 49 ||
          keyCode == 50 ||
          keyCode == 51 ||
          keyCode == 52)
      ) {
        $event.preventDefault();
      }
    },
    onlymobileNriFormat($event) {
      this.addnri.mobilenumbernra = '';
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }

      var nrimobileInput = document.getElementById("nrimobileInput");
      if (
        (nrimobileInput.value == "" || nrimobileInput.length > 1) &&
        (keyCode == 48 ||
          keyCode == 49 ||
          keyCode == 50 ||
          keyCode == 51 ||
          keyCode == 52)
      ) {
        $event.preventDefault();
      }
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      } else {
        return "N/A";
      }
    },
    format_timestamp(value) {
      if (value) {
        var localTime = new Date(value * 1000).toUTCString();
        return moment(localTime).format("DD/MM/YYYY @ hh:mm A");
      } else {
        return "N/A";
      }
    },
    clearall() {
      this.guesttype = "";
      // indian
      this.addind.mobilenumber = "";
      this.addind.aadharcardnumber = "";
      this.addind.visitreason = "";
      this.addind.visitingdatefrom = new Date();
      this.addind.visitingdateto = null;
      // Foreigner
      this.addnri.countryname = "";
      this.addnri.countrycodenra = "";
      this.addnri.mobilenumbernra = "";
      this.addnri.passportnumber = "";
      this.addnri.birthdate = null;
      this.addnri.visanumber = "";
      this.addnri.visiexpirydate = null;
      this.addnri.visitreasonnra = "";
      this.addnri.visitreasonoptionnra = "";
      this.addnri.visitingdatefromnir = new Date();
      this.addnri.visitingdatetonri = null;
      this.disablednriinput = false;
      this.displayMobileNriPopUp = false;
      this.passportNoFoundFlag = false;
      this.passportVerifySuccessFlag = false;
      this.isShowNriData = false;
      // OTP section
      this.showotploader = false;
      this.showverifyloader = false;
      this.searchbtnloader = false;
      this.searchMobileResult = [];
      this.disabledinput = false;
      this.mobileNoFoundFlag = false;
      this.timerMobileInterval = null;
      this.timerMobileStatus = false;
      this.timerMobileCount = 120;
      this.aadharOTPVerifySuccessFlag = false;
      this.hideaadharinput = false;
      this.displayMobilePopUp = false;
      this.aadharotp = "";
      this.mobileotperr = "";
      this.AadharTabDetails = "";
    },
    isLetterWithOutSpace(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) return true;
      else e.preventDefault();
    },
  },
};
</script>
<style scoped>
.form-box-outer {
  padding: 16px 16px;
  margin-bottom: 15px;
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
}

.search-voter-section-outer {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  height: 512px;
}

.search-voter-section-outer::-webkit-scrollbar {
  display: block;
}

.search-voter-section-outer::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.search-voter-section-outer::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}

.voter-exit-box-outer {
  padding: 16px 16px 0;
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
}

.customer-label-group {
  margin-bottom: 16px;
}

.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}

.customer-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}

.modal-bulk-next-btn.btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 35px;
  font-family: "AcuminPro-Regular";
  width: 115px;
}

.modal-bulk-next-btn:focus {
  box-shadow: none;
}

.voter-alert-group {
  font-family: "AcuminPro-Regular";
  font-size: 16px;
  letter-spacing: 1px;
  color: #303336;
}

.alert-body-bg {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
}

.voter-already-user-status {
  padding: 8px 16px;
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  margin-bottom: 15px;
  color: #63957d;
  font-weight: 700;
  font-family: "AcuminPro-SemiBold";
}

.divider-custom-list {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #d4e4f3;
}

.customer-label-group {
  margin-bottom: 16px;
}

.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}

.customer-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}

.border-guest-domestic {
  border: 1px solid #64a137;
}

.border-guest-international {
  border: 1px solid #059abd;
}

.user-profile-table-col {
  width: 10%;
}

.user-name-table-col {
  width: 30%;
}

.aadhar-resent-otp-btn {
  letter-spacing: 0px;
  color: #3362db;
  font-size: 14px;
  font-family: "AcuminPro-Regular";
  padding: 0;
  text-decoration: none;
}

.aadhar-resent-otp-btn:focus-visible {
  outline: 0;
}

.aadhar-resent-otp-btn:focus {
  box-shadow: none;
}
</style>